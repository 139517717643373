<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/unintended-2">我女兒懷孕了</a></li>
              <li class="breadcrumb-item"><a href="#/unintended-3-1">我想要生下來</a></li>
              <li class="breadcrumb-item"><a href="#/unintended-4-1">自行撫養</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">選擇答案</a></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜經濟狀態｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>生活層面</h3>
                        <p>孩子一出生接踵而來的就是奶粉錢、尿布錢、褓母費及各樣嬰兒用品的開 銷，若小孩抵抗力較弱，需要三天兩頭就醫，醫療費用也是很可觀的，而如果家人並不支持單親留養，你需要在外租屋，房租也會是固定大筆的支出，以上還不包含你個人的生活費用喔！所以你需要好好精算，目前的收入和存款是否足夠支付接連每個月所需的開銷，學習開源節流，調整自己的消費習慣，做好家庭金錢規劃。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>就業問題</h3>
                        <p>你可以申請勞動部所規劃的「職前訓練」，幫助你培養一技之長。在訓練期間也不用擔心生活費，只要你是獨自撫養小孩，或是符合低收入戶的標準，都可以申請職訓生活津貼補助，補助金額為每月基本工資百分之六十。<br>
                        補助條件為：需參加「全日制職業訓練」，並符合四個條件，即可申請。<br>
                          (一)、訓練期間1個月以上 <br>
                          (二)、每星期訓練4日以上<br>
                          (三)、每日訓練日間4小時以上<br>
                          (四)、每月總訓練時數達100小時以上<br><br>
                          建議你可以至
                          <a class="fs-5 text-danger" href="https://www.taiwanjobs.gov.tw/home109/index.aspx" target="_blank">台灣就業通</a>
                           獲得更多完整資訊。 </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>繼續完成學業</h3>
                        <p>為保障學生受教權，校方必須主動依學籍和成績評量等相關規定來做輔導，讓學生能夠在非預期懷孕後，回歸學校時再次適應學習環境，並依照他們的情況來調整彈性措施，讓面臨非預期懷孕的學生能夠順利完成學業。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>急難紓困金（原馬上關懷專案）</h3>
                        <p>
                          福利諮詢專線：1957。<br><br>
                          當家中負擔家庭主要生計的人死亡、失蹤、罹患重傷病、失業或因其他原因無法工作，導致家庭生活陷於困境時，就可以在事件發生的3個月內，向政府申請急難救助。<br>
                          只要民眾本人或親人，甚至是鄰里、村里長、學校等單位發現有急難救助需求的人時，都可以準備急難紓困申請書或通報表，向里辦公室、區公所或市政府申請或通報。<br>
                          訪視小組在通報後24小時內即會進行實地訪視，送核定機關當日核定後24小時內發放關懷救助金1~3萬元；若經過認定屬急迫個案，訪視時會立即先發給5,000元救助金。 若經救助無法紓困或不符規定，民眾可以再去申請地方政府、中央政府救助。
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>民間補助</h3>
                        <p>
                          除了公家機關之補助外，還可以申請民間單位的補助，相較於公家機關的公文往返時間過長，民間單位反而能夠給予較快的補助流程，能彌補政府所不足的部份，也做為第一線的救助，只是有些補助不能同時申請，所以在申請的時候需特別留意。<br><br>
                          《急難救助之服務項目及聯絡窗口》<br>
                          （110年6月01日更新)<br>
                        </p>
                        <div class="google-rwd">
                            <iframe src="https://www.google.com/maps/d/embed?mid=16KB92Kb7JwRarrx8WrUjoOWIEnOZDzcj&hl=zh-TW" width="640" height="480"></iframe>
                        </div>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Article></Article>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>

export default {
  data () {
    return {
    }
  },
  mounted () {

  }
}
</script>
